import { createSlice } from "@reduxjs/toolkit";
import {
  addInquiry,
  createPets,
  createSubscription,
  getBlogDetails,
  getBlogList,
  getBreeds,
  getfaqs,
  getPetsListByCustomerId,
  getPlans,
  getServiceDetailsById,
  getServices,
  isActiveSubscription,
  mySubscription,
  newsLetters,
  topBanners,
} from "./action";

const initialState = {
  isLoading: false,
  error: undefined,
  servicesList: null,
  addInquiryData: null,
  faqsList: [],
  BlogList: null,
  blogDetails: null,
  serviceDetails: null,
  topBannerList: null,
  newsLettersData: null,
  subscriptionData: null,
  plansData: null,
  mySubscriptionData: null,
  petsListData: null,
  addPetsData: null,
  isActiveSubscriptionData: null,
  testimonialList: null,
  breedsData: null,
};

const homeSlice = createSlice({
  name: "HOME",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getServices.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(getServices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.servicesList = action.payload;
    });
    builder.addCase(getServices.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(addInquiry.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(addInquiry.fulfilled, (state, action) => {
      state.isLoading = false;
      state.addInquiryData = action.payload;
    });
    builder.addCase(addInquiry.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getfaqs.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(getfaqs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.faqsList = action.payload;
    });
    builder.addCase(getfaqs.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getBlogList.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(getBlogList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.BlogList = action.payload;
    });
    builder.addCase(getBlogList.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getBlogDetails.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(getBlogDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.blogDetails = action.payload;
    });
    builder.addCase(getBlogDetails.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getServiceDetailsById.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(getServiceDetailsById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.serviceDetails = action.payload;
    });
    builder.addCase(getServiceDetailsById.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(topBanners.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(topBanners.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.type === "Testimonial") {
        state.testimonialList = action.payload;
      } else {
        state.topBannerList = action.payload;
      }
    });
    builder.addCase(topBanners.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(newsLetters.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(newsLetters.fulfilled, (state, action) => {
      state.isLoading = false;
      state.newsLettersData = action.payload;
    });
    builder.addCase(newsLetters.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(createSubscription.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(createSubscription.fulfilled, (state, action) => {
      state.isLoading = false;
      state.subscriptionData = action.payload;
    });
    builder.addCase(createSubscription.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getPlans.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(getPlans.fulfilled, (state, action) => {
      state.isLoading = false;
      state.plansData = action.payload;
    });
    builder.addCase(getPlans.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(mySubscription.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(mySubscription.fulfilled, (state, action) => {
      state.isLoading = false;
      state.mySubscriptionData = action.payload;
    });
    builder.addCase(mySubscription.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getPetsListByCustomerId.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(getPetsListByCustomerId.fulfilled, (state, action) => {
      state.isLoading = false;
      state.petsListData = action.payload;
    });
    builder.addCase(getPetsListByCustomerId.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(createPets.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(createPets.fulfilled, (state, action) => {
      state.isLoading = false;
      state.addPetsData = action.payload;
    });
    builder.addCase(createPets.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(isActiveSubscription.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(isActiveSubscription.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isActiveSubscriptionData = action.payload;
    });
    builder.addCase(isActiveSubscription.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getBreeds.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(getBreeds.fulfilled, (state, action) => {
      state.isLoading = false;
      state.breedsData = action.payload;
    });
    builder.addCase(getBreeds.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export default homeSlice.reducer;
