import axios from "axios";

axios.interceptors.request.use(
  async (config) => {
    config.baseURL = "https://telebark.git-test.in/public/api";
    // config.baseURL = process.env.REACT_APP_API_BASE_URL;

    const token = "CTeleiSOtCc3cFhOJFwXWSddh5i7OtKcmuBfEKvHlcPfGPwYegGOfBRAK";
    // const token = process.env.REACT_APP_TOKEN;
    if (token) {
      config.headers["authorization"] = `${token}`;
    }
    // config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const getRequestApi = (url, data, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers })
      .then((response) => {
        resolve(response?.data);
      })
      .catch(async (error) => {
        console.log("error====", error);
        reject(await getApiError(error));
      });
  });
};

export const postRequestApi = (url, data, headers, config) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, { headers, ...config })
      .then((response) => {
        console.log("response====????", response);
        resolve(response?.data);
      })
      .catch(async (error) => {
        console.log("error====post", error);
        reject(await getApiError(error));
      });
  });
};

export const deleteRequestApi = (url, data, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, { data, headers })
      .then((response) => {
        resolve(response?.data);
      })
      .catch(async (error) => {
        reject(await getApiError(error));
      });
  });
};

// use for update only a part of resource rather than entire resource.
export const patchRequestApi = (url, data, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(url, { headers, data })
      .then((response) => {
        resolve(response?.data);
      })
      .catch(async (error) => {
        reject(await getApiError(error));
      });
  });
};

// use for update entire resource with the entire version
export const putRequestApi = (url, data, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .put(url, data, { headers })
      .then((response) => {
        resolve(response?.data);
      })
      .catch(async (error) => {
        reject(await getApiError(error));
      });
  });
};

const getApiError = async (error) => {
  if (error?.response?.status === 500 || error?.response?.status === 422) {
    return {
      message: error?.response?.data?.message || "Something Went Wrong.",
      status: 500,
      error: true,
    };
  } else {
    return error;
  }
};
