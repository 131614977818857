const ApiConstant = {
  signUp: (name, email, password, confirm_password, lastname) =>
    `/register?name=${name}&email=${email}&phone=9900887766&password=${password}&confirm_password=${confirm_password}&lastname=${lastname}`,
  login: (username, password) =>
    `/login?username=${username}&password=${password}`,
  resetPassword: (email, password, confirm_password) =>
    `/reset-password?email=${email}&password=${password}&confirm_password=${confirm_password}`,
  getServices: "/services",
  addInquiry: (name, email, phone, service, message) =>
    `/add-inquiry?name=${name}&email=${email}&phone=${phone}&service=${service}&message=${message}`,
  getfaqs: "/faqs",
  getBlogList: "/blog-list",
  getServiceDetailsById: (id) => `/service-detail?service_id=${id}`,
  myProfile: (id) => `/my-profile?user_id=${id}`,
  forgetPassword: (email) => `/forget-password?email=${email}`,
  updateProfile: () => `/update-profile`,
  changePassword: (id, password, confirm_password, old_password) =>
    `/change-password?id=${id}&password=${password}&confirm_password=${confirm_password}&old_password=${old_password}`,
  getBlogDetails: (id) => `/blog-detail?blog_id=${id}`,
  cmsPages: "/cms-list",
  cmsDetailsById: (id) => `/cms-detail?cms_id=${id}`,
  topBanners: (type) => `/top-banners?type=${type}`,
  newsLetters: (email) => `/news-letters?email=${email}`,
  createSubscription: (
    customer_id,
    plan_id,
    amount,
    start_date,
    frequency,
    plan_type,
    no_of_pets,
    address_1,
    address_2,
    city,
    state,
    zipcode,
    used_shipping_address,
    payment_method,
    payment_token
  ) =>
    `/create-subscription?customer_id=${customer_id}&plan_id=${plan_id}&amount=${amount}&start_date=${start_date}&frequency=${frequency}&plan_type=${plan_type}&no_of_pets=${no_of_pets}&address_1=${address_1}&address_2=${address_2}&city=${city}&state=${state}&zipcode=${zipcode}&used_shipping_address=${used_shipping_address}&payment_method=${payment_method}&payment_token=${payment_token}`,
  getPlans: "/plans",
  mySubscription: "/subscription-list",
  getPetsList: "/pets",
  createPets: "/create-pets",
  isActiveSubscription: (customer_id) =>
    `is-active-subscription?customer_id=${customer_id}`,
  getBreeds: "/breeds",
};

export { ApiConstant };
