import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiConstant } from "../../api/apiEndPoints";
import { postRequestApi } from "../../api/axiosClient";

export const signup = createAsyncThunk('AUTH/userSignup', async (signUpData, { rejectWithValue }) => {
  try {
    const response = (await postRequestApi(
      ApiConstant.signUp(signUpData.name, signUpData.email, signUpData.password, signUpData.confirm_password, signUpData.lastname),
    ))
    console.log("response=---signup", response);
    if (response?.data) {
      localStorage.setItem('authUuid', response?.data?.uuid);
      localStorage.setItem('userInfo', JSON.stringify(response?.data));
      return response;
    } else {
      return rejectWithValue(response);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const signIn = createAsyncThunk('AUTH/userLogin', async (loginData, { rejectWithValue }) => {
  try {
    const response = (await postRequestApi(
      ApiConstant.login(loginData.username, loginData.password),
    ))
    if (response?.data) {
      localStorage.setItem('authUuid', response?.data?.uuid);
      localStorage.setItem('userInfo', JSON.stringify(response?.data));
      return response;
    } else {
      return rejectWithValue(response);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const resetPassword = createAsyncThunk('AUTH/resetPassword', async (Data, { rejectWithValue }) => {
  try {
    const response = (await postRequestApi(
      ApiConstant.resetPassword(Data.email, Data.password, Data.confirm_password),
    ))
    if (response) {
      console.log("response===", response);
      return response;
    } else {
      return rejectWithValue(response);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const forgetPassword = createAsyncThunk('AUTH/forgetPassword', async (Data, { rejectWithValue }) => {
  try {
    const response = (await postRequestApi(
      ApiConstant.forgetPassword(Data.email),
    ))
    if (response) {
      console.log("response?.data===", response);
      return response;
    } else {
      return rejectWithValue(response);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});
