import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiConstant } from "../../api/apiEndPoints";
import { getRequestApi, postRequestApi } from "../../api/axiosClient";

export const getServices = createAsyncThunk('HOME/getServices', async (_, { rejectWithValue }) => {
  try {
    const response = (await getRequestApi(
      ApiConstant.getServices,
    ))
    if (response?.data) {
      return response;
    } else {
      return rejectWithValue(response);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const addInquiry = createAsyncThunk('HOME/addInquiry', async (data, { rejectWithValue }) => {
  try {
    const response = (await postRequestApi(
      ApiConstant.addInquiry(data.name, data.email, data.phone, data.service, data.message),
    ))
    if (response?.data) {
      return response;
    } else {
      return rejectWithValue(response);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getfaqs = createAsyncThunk('HOME/getfaqs', async (_, { rejectWithValue }) => {
  try {
    const response = (await getRequestApi(
      ApiConstant.getfaqs,
    ))
    if (response?.data) {
      console.log("response?.data===", response?.data);
      return response;
    } else {
      return rejectWithValue(response);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getBlogList = createAsyncThunk('HOME/getBlogList', async (_, { rejectWithValue }) => {
  try {
    const response = (await getRequestApi(
      ApiConstant.getBlogList,
    ))
    if (response?.data) {
      return response;
    } else {
      return rejectWithValue(response);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getBlogDetails = createAsyncThunk('HOME/getBlogDetails', async (data, { rejectWithValue }) => {
  try {
    const response = (await postRequestApi(
      ApiConstant.getBlogDetails(data.id),
    ))
    if (response?.data) {
      return response;
    } else {
      return rejectWithValue(response);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getServiceDetailsById = createAsyncThunk('HOME/getServiceDetailsById', async (data, { rejectWithValue }) => {
  try {
    const response = (await postRequestApi(
      ApiConstant.getServiceDetailsById(data.id),
    ))
    if (response?.data) {
      return response;
    } else {
      return rejectWithValue(response);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const topBanners = createAsyncThunk('HOME/topBanners', async (data, { rejectWithValue }) => {
  try {
    const response = (await getRequestApi(
      ApiConstant.topBanners(data?.type),
    ))
    if (response?.data) {
      return {...response, type: data?.type};
    } else {
      return rejectWithValue(response);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const newsLetters = createAsyncThunk('HOME/newsLetters', async (Data, { rejectWithValue }) => {
  try {
    const response = (await postRequestApi(
      ApiConstant.newsLetters(Data.email),
    ))
    if (response) {
      console.log("response?.data===", response);
      return response;
    } else {
      return rejectWithValue(response);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createSubscription = createAsyncThunk('HOME/createSubscription', async (Data, { rejectWithValue }) => {
  try {
    const response = (await postRequestApi(
      ApiConstant.createSubscription(
        Data.customer_id,
        Data.plan_id,
        Data.amount,
        Data.start_date,
        Data.frequency,
        Data.plan_type,
        Data.no_of_pets,
        Data.address_1,
        Data.address_2,
        Data.city,
        Data.state,
        Data.zipcode,
        Data.used_shipping_address,
        Data.payment_method,
        Data.payment_token,
      ),
    ))
    if (response) {
      console.log("response?.data===", response);
      return response;
    } else {
      return rejectWithValue(response);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getPlans = createAsyncThunk('HOME/getPlans', async (_, { rejectWithValue }) => {
  try {
    const response = (await getRequestApi(
      ApiConstant.getPlans,
    ))
    if (response?.data) {
      return response;
    } else {
      return rejectWithValue(response);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const mySubscription = createAsyncThunk('HOME/mySubscription', async (customer_id, { rejectWithValue }) => {
  try {
    const data = new FormData();
    data.append('customer_id', customer_id);

    const response = (await postRequestApi(
      ApiConstant.mySubscription,
      data
    ))
    if (response?.data) {
      return response;
    } else {
      return rejectWithValue(response);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getPetsListByCustomerId = createAsyncThunk('HOME/getPetsList', async (customer_id, { rejectWithValue }) => {
  try {
    const data = new FormData();
    data.append('customer_id', customer_id);

    const response = (await postRequestApi(
      ApiConstant.getPetsList,
      data
    ))
    if (response?.data) {
      return response;
    } else {
      return rejectWithValue(response);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createPets = createAsyncThunk('HOME/createPets', async (Data, { rejectWithValue }) => {
  try {
    const data = new FormData();
    data.append('customer_id', Data?.customer_id);
    data.append('name', Data?.name);
    data.append('breeds', Data?.breeds);
    data.append('gender', Data?.gender);
    data.append('age', Data?.age);
    data.append('image', Data?.image);

    const response = (await postRequestApi(
      ApiConstant.createPets,
      data
    ))
    if (response?.data) {
      return response;
    } else {
      return rejectWithValue(response);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const isActiveSubscription = createAsyncThunk('HOME/isActiveSubscription', async (Data, { rejectWithValue }) => {
  try {
    const response = (await postRequestApi(
      ApiConstant.isActiveSubscription(Data.customer_id),
    ))
    if (response) {
      console.log("response?.data===", response);
      return response;
    } else {
      return rejectWithValue(response);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getBreeds = createAsyncThunk('HOME/getBreeds', async (_, { rejectWithValue }) => {
  try {
    const response = (await getRequestApi(
      ApiConstant.getBreeds,
    ))
    if (response?.data) {
      return response;
    } else {
      return rejectWithValue(response);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});