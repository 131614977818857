import { toast } from "react-toastify";

export const isAuthenticated = () => {
  // Replace with your actual authentication logic
  return localStorage.getItem("authUuid") ? true : false;
};

export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validatePassword = (password) => {
  return password.length >= 6;
};

// toast success message
export const notifySuccess = (message) => {
  toast(message, {
    type: "success",
    position: "top-right",
  });
};

// toast error message
export const notifyError = (message) => {
  toast(message, {
    type: "error",
    position: "top-right",
  });
};

export const getCurrentDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Add leading zero
  const day = String(currentDate.getDate()).padStart(2, "0"); // Add leading zero
  return `${year}-${month}-${day}`;
};
