import logo from "./logo.png";
import heart from "./heart.png";
import dog from "./dog.png";
import doganddoctor from "./dog&doctor.jpg";
import doganddoctor2 from "./doganddoctor2.jpg";
import Telehealth from "./Telehealth.jpg";
import Pharmacy from "./Pharmacy.jpg";
import VetDiscountPlan from "./VetDiscountPlan.jpg";
import LostPetServices from "./LostPetNotificationServices.jpg";
import PetRetailDiscounts from "./PetRetailDiscounts.jpg";
import PetHealthCloud from "./PetHealthCloud.jpg";
import Leftdog from "./leftdog.png";
import Rightdog from "./rightdog.png";
import getInTouch from "./getintouch.png";
import blogsAndNews1 from "./blogsAndNews1.jpg";
import blogsAndNews2 from "./blogsAndNews2.jpg";
import blogsAndNews3 from "./blogsAndNews3.jpg";
import Whatsapp from "./Whatsapp.png";
import Telegram from "./Telegram.png";
import Linkedin from "./Linkedin.png";
import Facebook from "./Facebook.png";
import Emma from "./Emma.jpg";
import Lisa from "./Lisa.jpg";
import Jason from "./jason.jpg";
import Coma from "./coma.png";
import Lostpetnotification from "./LostPetNotification.jpg";
import PetCloud from "./Petcloud.jpg";
import PetLogo from "./PetLogo.png";
import StoreEndless from "./StoreEndless.png";
import PawPinner from "./PawPinner.png";
import PawpinnerLogo from "./PawpinnerLogo.png";
import Arrow from "./Arrow.jpg";
import LeftArrow from "./leftArrow.jpg";
import RightArrow from "./RightArrow.jpg";
import CatPrescriptions from "./CatPrescriptions.jpg";
import DogPrescriptions from "./DogPrescriptions.jpg";
import MajorPharmacies from "./MajorPharmacies.jpg";
import VetDiscountHero from "./vetdiscounthero.jpg";
import discountIcon from "./discountIcon.png";
import Pet from "./Pet.png";
import Time from "./Time.png";
import Covers from "./Covers.png";
import calendar from "./calendar.png";
import injection from "./injection.png";
import NoDeductible from "./NoDeductible.png";
import Savevetcare from "./Savevetcare.png";
import Paperwork from "./Paperwork.png";
import logoTransparent from "./logoTransparent.png";
import ArrowWhite from "./ArrowWhite.png";
import PetPortal1 from "./PetPortal1.jpg";
import PetPortal2 from "./PetPortal2.jpg";
import PetPortal3 from "./PetPortal3.jpg";
import PetPortal4 from "./PetPortal4.jpg";
import PetportalnBG from "./petportalnbg.jpg";
import TelehealthImg from "./telehealthimg.jpg";
import SliderbarIcon from "./SliderbarIcon.png";
import SliderbarIconWhite from "./SliderbarIconWhite.png";
import Doctor from "./doctor.png";
import BtnArrow from "./btnarrow.png";
import checkIcon from "./check.png";
import crossIcon from "./cross.png";
import Insurance1 from "./insurance1.jpg";
import Insurance2 from "./insurance2.jpg";
import Insurance3 from "./insurance3.jpg";
import Insurance4 from "./insurance4.jpg";
import Insurance5 from "./insurance5.jpg";
import Insurance6 from "./insurance6.jpg";
import Insurance7 from "./insurance7.jpg";
import Insurance8 from "./insurance8.jpg";
import BlogDetails from "./BlogDetails.jpg";
import Adopt from "./Adopt.jpg";
import Featured1 from "./Featured1.jpg";
import Featured2 from "./Featured2.jpg";
import Featured3 from "./Featured3.jpg";
import Parenting from "./Parenting.svg";
import Alerts from "./Alerts.svg";
import Rehome from "./Rehome.svg";
import Essentials from "./Essentials.svg";
import DogDrop from "./DogDrop.jpg";
import DogHug from "./DogHug.jpg";
import UserProfile from "./UserProfile.png";
import UserProfileWhite from "./UserProfileWhite.png";
import LogoPink from "./LogoPink.svg"
import Maskgroup from "./Maskgroup.png"
import TrueIcon from "./Trueicon.png"
import Overview from "./Overview.svg"
import notFound from "./notFound.png"

export const imagesAss = {
  logo,
  heart,
  dog,
  doganddoctor,
  doganddoctor2,
  Telehealth,
  Pharmacy,
  VetDiscountPlan,
  LostPetServices,
  PetRetailDiscounts,
  PetHealthCloud,
  Leftdog,
  Rightdog,
  getInTouch,
  blogsAndNews1,
  blogsAndNews2,
  blogsAndNews3,
  Whatsapp,
  Telegram,
  Linkedin,
  Facebook,
  Emma,
  Jason,
  Lisa,
  Coma,
  Lostpetnotification,
  PetCloud,
  PetLogo,
  StoreEndless,
  PawPinner,
  PawpinnerLogo,
  Arrow,
  LeftArrow,
  RightArrow,
  CatPrescriptions,
  DogPrescriptions,
  MajorPharmacies,
  VetDiscountHero,
  discountIcon,
  Pet,
  Time,
  Covers,
  calendar,
  injection,
  NoDeductible,
  Savevetcare,
  Paperwork,
  logoTransparent,
  ArrowWhite,
  PetPortal1,
  PetPortal2,
  PetPortal3,
  PetPortal4,
  PetportalnBG,
  TelehealthImg,
  SliderbarIcon,
  SliderbarIconWhite,
  Doctor,
  BtnArrow,
  checkIcon,
  crossIcon,
  Insurance1,
  Insurance2,
  Insurance3,
  Insurance4,
  Insurance5,
  Insurance6,
  Insurance7,
  Insurance8,
  BlogDetails,
  Adopt,
  Featured1,
  Featured2,
  Featured3,
  Parenting,
  Alerts,
  Rehome,
  Essentials,
  DogDrop,
  DogHug,
  UserProfile,
  UserProfileWhite,
  LogoPink,
  Maskgroup,
  TrueIcon,
  Overview,
  notFound
};
